<script setup>
const uploads = [
  "Training Course Name",
  "Module Name",
  "Completion Date",
  "Completion Location",
  "Training Provider",
  "Certificate of achievement",
];
</script>
<template>
  <div class="flex-col flex h-full gap-5 justify-between">
    <div class="flex-1 flex gap-5 p-5">
      <div class="flex-grow">
        <div class="bg-gray-300 rounded-md p-5 h-full flex flex-col">
          <p class="text-2xl font-medium text-gray-600">Single Module</p>
          <div
            class="
              my-5
              bg-white
              flex flex-grow
              items-center
              justify-center
              rounded-md
            "
          >
            Single Module Image
          </div>

          <p class="my-5 text-gray-600">
            Perfect for training that has only one component or module. This
            might be a training course that you completed in person or online
            that you recieved a certificate for.
          </p>
          <h3 class="text-teal font-medium">Upload</h3>
          <p class="text-gray-600" v-for="item in uploads" :key="item">
            {{ item }}
          </p>
        </div>
      </div>
      <div class="flex-grow">
        <div class="bg-gray-300 rounded-md p-5 h-full flex flex-col">
          <p class="text-2xl font-medium text-gray-600">Multi Module</p>
          <div
            class="
              my-5
              bg-white
              flex flex-grow
              items-center
              justify-center
              rounded-md
            "
          >
            Multi Module Image
          </div>
          <p class="my-5 text-gray-600">
            Perfect for training with multiple elements. This could be a
            training course in which you completed exams. The training course
            may be re-occurring in the future, or mandatory for your peers.
          </p>
          <h3 class="text-teal font-medium">Upload</h3>

          <p class="text-gray-600" v-for="item in uploads" :key="item">
            {{ item }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
